import * as serviceSubtypeService from './service/service-subtype.service';
const state = {
    serviceSubtype: null,
    serviceSubtypes: [],
    serviceSubtypesPagination: {
        page: 1,
        limit: 10,
        totalItems: 0,
        totalPages: 0,
    },
    allServiceSubtypes: [],
    loadingServiceSubtype: false,
};

const mutations = {
    SET_LOADING_SERVICE_SUBTYPE(state, data) {
        state.loadingServiceSubtype = data;
    },

    SET_SERVICE_SUBTYPE(state, serviceSubtype) {
        state.serviceSubtype = serviceSubtype;
    },

    SET_ALL_SERVICE_SUBTYPES(state, data) {
        state.allServiceSubtypes = data;
    },

    SET_SERVICE_SUBTYPES(state, payload) {
        state.serviceSubtypes = payload;
    },

    SET_SERVICE_SUBTYPES_PAGINATION(state, payload) {
        state.serviceSubtypesPagination = { ...state.serviceSubtypesPagination, ...payload };
    },
};

const actions = {
    async createServiceSubtype(__, data) {
        try {
            console.log('data',data)
            const response = await serviceSubtypeService.saveServiceSubtype(data);
            return { ok: true, response };
        } catch (error) {
            return { ok: false, error: error?.response || "Error en el servicio" };
        }
    },

    async getServiceSubtypeById({ commit }, id) {
        try {
            const response = await serviceSubtypeService.getServiceSubtypeById(id);
            commit("SET_SERVICE_SUBTYPE", response);
            return { ok: true, response };
        } catch (error) {
            return { ok: false, error: error?.response || "Error en el servicio" };
        }
    },

    async listAllServiceSubtypes({ commit }, data) {
        try {
            commit("SET_ALL_SERVICE_SUBTYPES", []);
            const response = await serviceSubtypeService.getAllServiceSubtypes(data);
            commit("SET_ALL_SERVICE_SUBTYPES", response.data);
            return { ok: true, response };
        } catch (error) {
            return { ok: false, error: error?.response || "Error en el servicio" };
        }
    },

    async listServiceSubtypePagination({ commit }, data) {
        try {
            commit("SET_SERVICE_SUBTYPES", []);
            const response = await serviceSubtypeService.getAllServiceSubtypes(data);
            const pagination = {
                page: response?.data.page,
                limit: response?.data.limit,
                totalItems: response?.data.totalItems,
                totalPages: response?.data.totalPages,
            };
            commit("SET_SERVICE_SUBTYPES", response?.data || []);
            commit("SET_SERVICE_SUBTYPES_PAGINATION", pagination || {});
            return { ok: true, response };
        } catch (error) {
            return { ok: false, error: error?.response || "Error en el servicio" };
        }
    },

    async editServiceSubtype(__, data) {
        try {
          const response = await serviceSubtypeService.updateServiceSubtype(data);
          return { ok: true, response };
        } catch (error) {
          return { ok: false, error: error?.response || "Error en el servicio" };
        }
      },

    cleanServiceSubtype({ commit }) {
        commit("SET_SERVICE_SUBTYPES", []);
        commit("SET_SERVICE_SUBTYPES_PAGINATION", {
            page: 0,
            limit: 0,
            totalItems: 0,
            totalPages: 0,
        });
    },
};

const getters = {};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
